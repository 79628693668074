import { useCallback, useEffect, useState } from "react";
import { ContributorUpdatedFilms } from "types";
import { useUser } from "./useUser";
import {getContributorMovies as getMovies} from "@services/prepr.service"

export const useContributorMovies = () => {
  const [contributorFilms, setContributorFilms] = useState<
    ContributorUpdatedFilms[]
  >([]);

  const user = useUser();
  
  const getContributorMovies = useCallback(async () => {
    if (user) {
      const movies = await getMovies(); 
  
      setContributorFilms(movies);
    }
  }, [user]);

  useEffect(() => {
    getContributorMovies();
  },[getContributorMovies])
  
  return contributorFilms;
};
