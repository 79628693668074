import { useRef } from "react";
import { Line } from "react-chartjs-2";
import styles from "../../styles/components/Chart.module.sass";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartArea,
  Filler,
  Point,
  ChartOptions,
} from "chart.js";
import { useEffect, useState } from "react";
import { Loader } from "@components/Loader/Loader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  borderWidth: 4,
  fill: true,
  tension: 0.2,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        color: "#9DA8BA",
        display: false,
      },
      grid: {
        drawBorder: false,
      },
    },
    x: {
      ticks: {
        padding: 20,
        color: "red",
        display: false,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },
};

const COLORS = ["#FFCC00", "#FFCC00", "#FFCC00"];

function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(1, "rgba(255, 204, 0, 0.6)");
  gradient.addColorStop(0, "rgba(255, 204, 0, 0)");

  return gradient;
}

export const Chart = ({ title = "", data, loading = false }: any) => {
  const chartRef = useRef<ChartJS<"line", (number | Point)[], unknown>>(null);
  const [_data, setData] = useState(null);

  useEffect(() => {
    if (data) {
      data.datasets.forEach((dataset: any, index: number) => {
        let temp = data;
        temp.datasets[index] = {
          ...dataset,
          borderColor: COLORS[index],
          backgroundColor: chartRef.current
            ? createGradient(chartRef.current.ctx, chartRef.current.chartArea)
            : "red",
          pointStyle: "circle",
          pointWidth: 10,
          pointBorder: 10,
          pointBackgroundColor: "rgba(0, 0, 0, 0)",
          pointBorderColor: "rgba(0, 0, 0, 0)",
          pointHoverBackgroundColor: COLORS[index],
          pointHoverBorderColor: COLORS[index],
          pointHoverRadius: 6,
          fill: true,
        };
        setData(temp);
      });
    }
  }, [data]);

  return (
    <div className={`${styles.box} relative`}>
      <div
        className={`absolute w-full h-full bg-popflick-carbon bg-opacity-90 ${
          loading ? "flex" : "hidden"
        } justify-center items-center z-10`}
      >
        <Loader color="yellow" />
      </div>
      <div className={styles.header}>
        <label className={styles.title}>{title}</label>
        {/* <label className={styles.export}>Export</label> */}
      </div>
      <div className="spacer"></div>
      <div className={styles.container}>
        {_data && (
          <Line
            className={styles.container}
            ref={chartRef}
            options={options as ChartOptions<"line">}
            data={_data}
          />
        )}
      </div>
      <div className={`flex col justify-between my-2 ${styles.bottom}`}>
        {data?.labels ? (
          <>
            <label className="text-white">{data.labels[0]}</label>
            <label className="text-white">
              {data.labels[data.labels.length - 1]}
            </label>
          </>
        ) : null}
      </div>
    </div>
  );
};
