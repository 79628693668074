import axios from 'axios';
import { jsonToFormData } from '../utils';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import Cookies from 'js-cookie';
import { ContributorUpdatedFilms } from 'types';

const serverUrl: string = process.env.NEXT_PUBLIC_API_SERVER_URL;

export const saveMovieToPrepr = async (filmData, uploadId,  posterHorizontal, posterVertical, imageCover,
  mobileImageCover, closedCaption, subtitles, trailerSubtitles, awardsImages) => {
  const body: any = {
    'movieTitle': filmData.title,
    'licenses': filmData.licenses,
    'tvRating': filmData.tvrating,
    'year': filmData.year,
    'description': filmData.description,
    'tags': filmData.tags,
    'director': filmData.director,
    'cast': filmData.cast,
    'language': filmData.language,
    'categories': filmData.categories,
    'subCategories': filmData.subCategories,
    'contributorId': filmData.contributorId,
    'movie': filmData.movie,
    'trailer': filmData.trailer,
    'posterHorizontal': posterHorizontal,
    'posterVertical': posterVertical,
    'imageCover': imageCover,
    'mobileCoverImage': mobileImageCover,
    'awardsImages': awardsImages,
    'closedCaption': closedCaption,
    'subtitles': subtitles,
    'trailerSubtitles': trailerSubtitles,
    'uploadId': uploadId,
    'stripePrice': filmData.stripePrice,
  };

  const apiUrl = serverUrl + '/endpoint/prepr/';
  return await axios(apiUrl, {
    method: 'POST',
    data: pickBy(body, identity),
    headers: {
      'Authorization': `Bearer ${ Cookies.get('access') }`,
    },
  }).then(r => r.data);
};

export const updateMovieToPrepr = async (
  publicationId,
  filmData,
  closedCaption, subtitles,
  trailerSubtitles) => {
  const body: any = {
    publicationId,
    'movieTitle': filmData.title,
    'year': filmData.year,
    'licenses': filmData.licenses,
    'categories': filmData.categories,
    'contributorId': filmData.contributorId,
    'trailer': filmData.trailer,
    'closedCaption': closedCaption,
    'subtitles': subtitles,
    'trailerSubtitles': trailerSubtitles,
    'stripePrice': filmData.stripePrice,
  };

  const apiUrl = serverUrl + '/endpoint/prepr/';
  return await axios(apiUrl, {
    method: 'PUT',
    data: pickBy(body, identity),
    headers: {
      'Authorization': `Bearer ${ Cookies.get('access') }`,
    },
  }).then(r => r.data);
};

export const getContributorMovies = async () => {
  try {
    const apiUrl = serverUrl + '/endpoint/prepr/';

    const res = await axios(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${ Cookies.get('access') }`,
      },
    })

    const data: ContributorUpdatedFilms[] = [];
    if (res.status === 200) {
      res.data.data.items.forEach((item: any) => {
        const posterUrl = item.items["en-US"]?.poster_horizontal?.items[0].cdn_files.items[0].url.replace('{format}', 'webp') || ''
        data.push({
          slug: item.slug["en-US"] || '',
          title: item.items["en-US"].title.body || '',
          posterHorizontal: posterUrl,
          changedOn: item.changed_on || '',
          publishOn: item.publish_on ? item.publish_on["en-US"] : '',
          createdOn: item.created_on || '',
          status: item.status["en-US"].body || '',
        })
      });
    }

    return data;
  } catch (error) {
    return []
  }
}

export const createAsset = async (file, crop?) => {

  const data = {};
  let formData = jsonToFormData(data);

  formData.append('file', file, file.name);
  if (crop) {
    formData.append('crop', JSON.stringify(crop));
  }

  const apiUrl = serverUrl + '/endpoint/prepr-assets/';
  const result = await axios.post(apiUrl, formData, {
    headers: {
      'Authorization': `Bearer ${ Cookies.get('access') }`,
    },
  }).then(r => r.data);

  return result.data;
};

export const saveSeriesToPrepr = async (seriesData, posterHorizontal, 
  posterVertical, trailerSubtitles, ) => {
  const body: any = {
    'seriesTitle': seriesData.title,
    'licenses': seriesData.licenses,
    'tvRating': '',
    'year': seriesData.year,
    'description': seriesData.description,
    'tags': [],
    'director': seriesData.director,
    'cast': [],
    'language': '',
    'categories': seriesData.categories,
    'contributorId': seriesData.contributorId,
    'trailer': seriesData.trailer,
    'posterHorizontal': posterHorizontal,
    'posterVertical': posterVertical,
    'trailerSubtitles': trailerSubtitles,
    'stripePrice': seriesData.stripePrice,
    'seasonsData': seriesData.seasonsData
  };

  const apiUrl = serverUrl + '/endpoint/prepr/';
  return await axios(apiUrl, {
    method: 'POST',
    data: pickBy(body, identity),
    headers: {
      'Authorization': `Bearer ${ Cookies.get('access') }`,
    },
  }).then(r => r.data);
};

export const checkContent = async (title, year) => {

  const apiUrl = `${ serverUrl }/endpoint/content-exist/${ title }/${ year }`;
  const result = await axios.get(apiUrl, {
    headers: {
      'Authorization': `Bearer ${ Cookies.get('access') }`,
    },
  }).then(r => r.data);

  return result.data;
};