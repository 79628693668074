import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import {
  getLastQuarter,
  getMonthName,
  getSelectedMonthOpt,
  getSelectedYearOpt,
} from "../../utils";
import {
  endOfDay,
  endOfMonth,
  getMonth,
  isThisYear,
  setMonth,
  setYear,
  startOfMonth,
} from "date-fns";
import { useUser } from "hooks/useUser";
import { useContributorMovies } from "hooks/useContributorMovies";
import { useRouter } from "next/router";
import format from "date-fns/format";
import ContributorDropdown from "@components/ContributorDropdown";

export default function DateRangeSelect({
  dataUpdaterHandler = (
    initDate: any,
    endDate: any,
    contributor: any,
    slug: any
  ) => {},
}) {
  const user = useUser();
  const movies = useContributorMovies();
  const router = useRouter();
  const { slug, ini, end } = router.query;
  const isAdmin = user && user.groups.includes("Admins");
  const selectedMonthOp = getSelectedMonthOpt(ini, end);
  const selectedYearOp = getSelectedYearOpt(ini, end);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const yearOpts = Array.from(
    { length: currentYear - 2022 + 1 },
    (elm, index) => {
      const fullYear = currentYear - index;
      return { value: fullYear, label: fullYear };
    }
  );
  const [selectedMonth, setSelectedMonth] = useState<{
    value?: string | number;
    label?: string;
  }>(
    ini !== undefined
      ? { value: selectedMonthOp.value, label: selectedMonthOp.label }
      : null
  );
  const [selectedYear, setSelectedYear] = useState({
    value: ini !== undefined ? selectedYearOp.value : currentYear,
    label: ini !== undefined ? selectedYearOp.label : currentYear,
  });
  const [selectedMovie, setSelectedMovie] = useState({
    value: "",
    label: "",
  });
  const [monthOpts, setMonthOpts] = useState(null);
  const [movieOpts, setMovieOpts] = useState(null);
  const [contributor, setContributor] = useState(null);
  const [movieSlug, setMovieSlug] = useState(slug);
  const [moviesLoading, setMoviesLoading] = useState(true);

  // Each quarter represent 3 months
  const monthsByQuarter = useMemo(() => 3, []);

  useEffect(() => {
    setSelectedMonth({
      value: ini !== undefined ? selectedMonthOp.value : currentMonth,
      label:
        ini !== undefined
          ? selectedMonthOp.label
          : getMonthName(currentMonth),
    });
  }, [currentMonth]);

  useEffect(() => {
    const today = new Date();
    const isCurrentYear = isThisYear(setYear(today, selectedYear.value));
    const currentMonth = getMonth(today);
    let monthLength = 12;
    let monthOpts = [];

    if (isCurrentYear) {
      monthLength = currentMonth + 1;
    }

    const quarterDiff =
      isCurrentYear && !isAdmin ? monthLength - 3 : monthLength;

    // Quarters are periods of 3 months
    let quarterOptions = Array.from(
      { length: Math.ceil(quarterDiff / monthsByQuarter) },
      (elm, index) => {
        return { value: `Q${index + 1}`, label: `Quarter ${index + 1}` };
      }
    );

    if (quarterOptions.length === 0) {
      quarterOptions = [{'value':'Q1','label':'Quarter 1'}]
    }

    if (!isAdmin) quarterOptions = [];

    //if (isAdmin) {
    monthOpts = Array.from({ length: monthLength }, (elm, index) => {
      return { value: index, label: getMonthName(index) };
    });
    //}

    setMonthOpts([...quarterOptions, ...monthOpts]);
  }, [isAdmin, selectedYear]);

  const applyFilter = () => {
    const month = parseInt(selectedMonth.value?.toString(), 10);

    // The filter should include all dates within a given month.
    let initPeriod = month;
    let endPeriod = month;

    if (isNaN(month)) {
      const quarter = selectedMonth.value;
      const period = parseInt(quarter.toString().replace("Q", ""));
      // Each quarter represent 3 months
      initPeriod = (period - 1) * monthsByQuarter;
      // A month is automatically included within the initPeriod.
      endPeriod = initPeriod + (monthsByQuarter - 1);
    }

    // Start of period
    const startOfPeriod = new Date(selectedYear.value, initPeriod);
    const initDate = startOfMonth(startOfPeriod);
    const endDate = endOfDay(endOfMonth(setMonth(initDate, endPeriod)));

    dataUpdaterHandler(
      format(initDate, "yyyy-MM-dd"),
      format(endDate, "yyyy-MM-dd"),
      contributor,
      movieSlug
    );
  };

  const handleOnChangeSelectedMovie = useCallback(
    (selectedOption) => {
      setSelectedMovie(selectedOption);
      setMovieSlug(selectedOption.value);
    },
    [selectedMovie]
  );

  useEffect(() => {
    if (selectedMonth) {
      applyFilter();
    }
  }, [selectedYear, selectedMonth, contributor, movieSlug]);

  useEffect(() => {
    if (movies) {
      const movieOptions = movies.reduce((acc, movie) => {
        const movieOpt = { value: movie.slug, label: movie.title };

        if (movie.slug === slug) {
          setSelectedMovie(movieOpt);
        }

        acc.push(movieOpt);

        return acc;
      }, []);
      setMovieOpts(() => {
        return movieOptions;
      });

      if (movieOptions.length > 0) {
        setMoviesLoading(false);
      }
    }
  }, [movies]);

  const onChangeContributor = (selectedContributor) => {
    if (selectedContributor) {
      setContributor(
        selectedContributor.id != "all"
          ? selectedContributor.email
          : selectedContributor.id
      );
    }
  };

  return (
    <div className="flex flex-row items-center gap-8 flex-wrap">
      {slug ? (
        <div className="col-auto flex items-center">
          <Select
            value={selectedMovie}
            onChange={handleOnChangeSelectedMovie}
            options={movieOpts}
            className="react-select react-select-carbon-light w-[150px] md:w-[200px]"
            classNamePrefix="react-select"
            isLoading={moviesLoading}
          />
        </div>
      ) : (
        isAdmin && (
          <div className="col-auto flex items-center">
            <ContributorDropdown
              disabled={false}
              loading={false}
              contributorId={contributor}
              onChange={onChangeContributor}
              selectClassName={
                "react-select react-select-carbon-light w-[150px] md:w-[200px]"
              }
              full={false}
            />
          </div>
        )
      )}
      <div className="col-auto flex items-center">
        <Select
          value={selectedYear}
          onChange={(selectedOption) => setSelectedYear(selectedOption)}
          options={yearOpts}
          className="react-select react-select-carbon-light w-[150px] md:w-[200px]"
          classNamePrefix="react-select"
        />
      </div>
      <div className="col-auto flex items-center">
        <Select
          isSearchable={false}
          value={selectedMonth}
          onChange={(selectedOption) => setSelectedMonth(selectedOption)}
          options={monthOpts}
          className="react-select react-select-carbon-light w-[150px] md:w-[200px]"
          classNamePrefix="react-select"
        />
      </div>
    </div>
  );
}
