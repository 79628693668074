import styles from '../../styles/components/Loader.module.sass';

export const Loader = ({ color = "" }) => (
  <div className={`${styles.lds_ring} ${styles[color]}`}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
