import { Line } from 'react-chartjs-2';
import { Box } from '../index';
import styles from '../../styles/components/Metric.module.sass';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const options = {
  responsive: true,
  layout: {
    padding: 0,
  },
  scales: {
    y: {
      ticks: {
        display: false,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    x: {
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const Metric = ({
  title = '',
  value = '',
  percentage = '',
  data,
  positive,
}: any) => {
  return (
    <Box customClass={ styles.box }>
      <div className={ styles.container }>
        <div className={ styles.data }>
          <label className={ styles.title }>{ title }</label>
          <label className={ styles.value }>{ value }</label>
          <label className={ styles.percentage }>{ percentage }</label>
        </div>
        <div className={ styles.chart }>
          <Line options={ options } data={ data }/>
        </div>
      </div>
    </Box>
  );
};
