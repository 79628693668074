import { useEffect } from 'react';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import styles from '../../styles/components/Table.module.sass';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';

export const Table = ({
    columns = [],
    data = [],
    paginationSize = 15,
  }: any) => {
    const tableInstance: any = useTable(
      {
        columns,
        data,
        // initialState: {pageIndex: 0},
      },
      useSortBy,
      usePagination,
      useRowSelect,
    );
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      pageCount,
      prepareRow,
      state: { pageIndex, pageSize },
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      setPageSize,
      gotoPage,
    } = tableInstance;


    useEffect(() => {
      setPageSize(paginationSize);
    }, [pageSize]);

    return (
      <>
        <table
          className={ `table table-dark table-auto table-striped rounded border border-collapse m-0 ${ styles.container || '' }` } { ...getTableProps() }>
          <thead
            className={ classNames(`thead-dark`, styles.header) }>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup: any) => {
              const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
              // Apply the header row props
              return (
                <tr key={ key } { ...restHeaderGroupProps }>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column: any) => {
                      const { key, ...restColumn } = column.getHeaderProps(column.getSortByToggleProps());
                      // Apply the header cell props
                      return (
                        <th className={ 'text-left' } key={ key } { ...restColumn }>
                          {
                            column.render('Header')
                          }
                          <span className="ml-1">
                            { column.isSorted
                              ? column.isSortedDesc
                                ? <i className="fa-solid fa-caret-up"></i>
                                : <i className="fa-solid fa-caret-down"></i>
                              : '' }
                            </span>
                        </th>
                      );
                    })
                  }
                </tr>
              );
            })
          }
          </thead>
          <tbody { ...getTableBodyProps() }>
          {
            // Loop over the table rows
            page.map((row: any, i: number) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr key={ i } className={ styles.row } { ...row.getRowProps() }>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell: any, index: number) => {

                      // Apply the cell props
                      return (
                        <td
                          key={ index }
                          { ...cell.getCellProps() }
                        >
                          { cell.render('Cell') }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
          </tbody>
        </table>
        <div className={ 'flex justify-end' }>
          <small>
            { (pageIndex + 1) * pageSize - (pageSize - 1) } to{ ' ' }
            { (pageIndex + 1) * pageSize >= data.length
              ? data.length
              : (pageIndex + 1) * pageSize }{ ' ' }
            items of { data.length }
          </small>
        </div>
        { pageCount ? <div className={ styles.pagination }>
          <ReactPaginate
            breakLabel="..."
            nextLabel={ <i className="fa-solid fa-chevron-right"></i> }
            onPageChange={ ({ selected }) => {
              gotoPage(selected);
            } }
            pageRangeDisplayed={ 5 }
            marginPagesDisplayed={ 2 }
            pageCount={ pageCount }
            forcePage={ pageIndex }
            previousLabel={ <i className="fa-solid fa-chevron-left"></i> }
            renderOnZeroPageCount={ null }
            containerClassName={ 'react-paginate' }
            disableInitialCallback={ true }
          />
        </div> : <></> }
      </>
    );
  }
;
